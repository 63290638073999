body {
    padding: 0 !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.layout-root {
    /*background-image: url('/content/images/background/AchtergrondAfbeelding.png');*/
    /*background-image: url('/content/images/background/Achtergrond-web-portal.png');*/
    /*background-image: url('/content/images/background/project-40120681-cubic-foto-2.jpg');*/
    background-size: cover;
    background-attachment: fixed;
    background-position: center bottom;
    min-height: 100vh;
}
