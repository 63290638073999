.login-grid {
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.login-grid .login-container {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 2em;
    width: 420px;
    max-width: 100%;
}

.login-grid .login-container .remember-me {
    margin-bottom: 0;
}

.versionInfo {
    text-shadow: 0 0 10px rgb(0 0 0);
    color: white;
    position: fixed;
    bottom: 5px;
    right: 5px;
}